import log from "./log"

const identity = value => value

export const createStorage = (
  name,
  defaultValue = null,
  normalize = identity
) => {
  const storageKey = `yoursever_${name}`
  const listeners = []

  let isLoaded = false
  let value

  const on = listener => listeners.push(listener)

  const off = listener => {
    const index = listeners.indexOf(listener)
    if (index !== -1) listeners.splice(index, 1)
  }

  const val = () => normalize(value || defaultValue)

  const load = () => {
    try {
      const jsonValue = window.localStorage.getItem(storageKey)
      return jsonValue ? JSON.parse(jsonValue) : undefined
    } catch (error) {
      log(`[storage:${name}:load][error] - error: ${error}`)
    }
  }

  const get = () => {
    if (!isLoaded) {
      value = load()
      log(`[storage:${name}:load] - value: ${JSON.stringify(value)}`)
      isLoaded = true
    }

    return val()
  }

  const set = newValue => {
    if (!isLoaded) get()

    const jsonValue = JSON.stringify(newValue)
    if (JSON.stringify(value) === jsonValue) return

    value = jsonValue && JSON.parse(jsonValue)
    log(`[storage:${name}:set] - value: ${jsonValue}`)

    for (const listener of listeners) {
      listener(value)
    }

    try {
      jsonValue
        ? window.localStorage.setItem(storageKey, jsonValue)
        : window.localStorage.removeItem(storageKey)
    } catch (error) {
      log(`[storage:${name}:set][error] - error: ${error}`)
    }

    return val()
  }

  const clear = () => set()

  return { name, get, set, clear, on, off }
}

export const updateStorage = createStorage(`update`, 0, Number)
export const userStorage = createStorage(`user`, {})
export const emailStorage = createStorage(`email`, ``, String)
export const qaStorage = createStorage(`qa`, false, Boolean)
export const highContrastStorage = createStorage(
  `high_contrast`,
  false,
  Boolean
)
export const invertColorsStorage = createStorage(
  `invert_colors`,
  false,
  Boolean
)
