import { updateStorage } from "./storage"
import log from "./log"
import { getScrollTop } from "./dom"

const buildTime = process.env.GATSBY_BUILD_TIME

export const markReady = () => updateStorage.set(buildTime)
export const isReady = () => updateStorage.get() === buildTime
export const addListener = listener => updateStorage.on(listener)
export const removeListener = listener => updateStorage.off(listener)

export const reload = () => {
  updateStorage.clear()
  window.location.reload()
}

const formatBuildTime = time => time && (new Date(time * 1000) || time)

const visitTime = new Date()
addListener(newBuild => {
  const scrollTop = getScrollTop()
  const uptime = (new Date() - visitTime) * 0.001
  if (newBuild && scrollTop < 10 && uptime < 60) {
    log(
      `[offline] - auto reloading - scroll top: ${scrollTop}px - uptime: ${uptime.toFixed(
        1
      )}s`
    )
    reload()
  }
})

if (!isReady()) updateStorage.clear()
log(
  `[offline] - build time: ${formatBuildTime(
    buildTime
  )} - update: ${formatBuildTime(updateStorage.get())}`
)
