/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { markReady } from "./src/utils/offline"
import "./src/styles/reset.scss"
import "./src/styles/colors.scss"

export const onServiceWorkerUpdateReady = () => markReady()
