export const getScrollTop = () =>
  window.pageYOffset ||
  (document.documentElement.clientHeight
    ? document.documentElement.scrollTop
    : document.body.scrollTop) ||
  0

export const focusDOM = (elementId, { wait = 200, select = false } = {}) =>
  setTimeout(() => {
    const element = document.getElementById(elementId)
    if (!element) return
    if (select && element.select) element.select()
    if (element.focus) element.focus()
  }, wait)
